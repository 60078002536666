// Try to do dark mode?
body {
  color: $gray-900;
  background-color: $gray-100;
  transition: background-color 0.5s ease-in-out, color;

  @media screen and (prefers-color-scheme: dark) {
    color: $gray-100;
    background-color: $black;
  }
}

.card {
  @media screen and (prefers-color-scheme: dark) {
    background-color: $gray-900;
    border-color: $gray-800;
  }
}

.form-control {
  @media screen and (prefers-color-scheme: dark) {
    color: $white;
    background-color: $gray-800;
    border-color: $gray-700;

    &:focus {
      color: $white;
      background-color: $gray-700;
      border-color: $gray-600;
    }
  }
}

.dropdown-menu {
  @media screen and (prefers-color-scheme: dark) {
    color: $gray-100;
    background-color: $gray-900;
    border-color: rgba($white, .15);

    .dropdown-header {
      color: $gray-600;
    }

    .dropdown-divider {
      border-top-color: $gray-800;
    }

    .dropdown-item {
      color: $gray-500;

      &:hover {
        background-color: $gray-700;
      }
    }
  }
}

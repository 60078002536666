.sign-in-form {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;

  input[type="email"] {
    margin-bottom: -1px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  input[type="password"] {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .form-control {
    height: auto;
    padding: 10px;
  }
}

.account-avatar {
  color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  .initials {
    font-size: 13px;
  }
}

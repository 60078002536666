.navigation-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;

  .profile-toggle {
    cursor: pointer;
  }

  .account-display-name {
    font-size: 16px;
  }
}
